<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="StocksOverview"/>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-0 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <StocksListingV2/>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import ExportTable from "Components/Appic/ExportTable";
    import {mapFields} from "vuex-map-fields";

    const StocksListingV2 = () => import("Components/Appic/StocksListingV2");

    export default {
        name: "StocksOverview",
        components: {ExportTable, StocksListingV2},
        data() {
            return {
                loader: false
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
        }
    }
</script>

<style scoped>

</style>